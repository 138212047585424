import React, { useEffect, useState } from 'react';

const Loader = () => {
  const [isError, setIsError] = useState(false);
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
    }, 1000);

    const timeout = setTimeout(() => {
      setIsError(true);
      clearInterval(interval);
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <p>
        {isError
          ? 'Error. Try later'
          : `Loading... ${counter > 0 ? counter : ''}`}
      </p>
    </div>
  );
};

export default Loader;
